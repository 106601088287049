<template>
  <FileContainersTable
    :items="fileContainers"
    :total-size="totalSize"
    :page-size="pageSize"
    :page-number="pageNumber"
    :loading="isLoading"
    :features="features"
    :title="$t('file-containers.table.title')"
    @pageChanged="pageChanged"
    @itemCreated="onItemCreated"
    @itemUpdated="onItemUpdated"
    @itemDeleted="onItemDeleted"
    @sortingChanged="onSortingChanged"
    @refreshRequested="onRefreshRequested"
  />
</template>

<script>
import debounce from 'lodash/debounce'
import { mapActions, mapGetters } from 'vuex'
import FileContainersTable from '@/components/tables/FileContainersTable'

export default {
  components: { FileContainersTable },
  data () {
    return {
      fileContainers: [],
      isLoading: true,
      totalSize: 0,
      pageSize: 25,
      pageNumber: 1,
      latestSorting: undefined
    }
  },
  computed: {
    ...mapGetters('account', ['isOverlord']),
    features () {
      return this.isOverlord ? ['create', 'update', 'delete'] : ['create', 'update']
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('fileContainers', ['fetchFileContainers']),
    onItemUpdated (updatedFileContainer) {
      // Try to find the updated file container among the currently shown file containers.
      const index = this.fileContainers.findIndex((fileContainer) => fileContainer.id === updatedFileContainer.id)
      if (index !== -1) {
        // Update the file container to the new values.
        // $set is a special method to allow vue to react to array changes.
        // See https://vuejs.org/v2/guide/reactivity.html for more info.
        this.$set(this.fileContainers, index, updatedFileContainer)
      }
    },
    async onItemDeleted (deletedFileContainer) {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async onItemCreated (newFileContainer) {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async onRefreshRequested () {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async pageChanged (newPageNumber) {
      // Update the current page number.
      this.pageNumber = newPageNumber
      // Fetch data with the new page number.
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async onSortingChanged (newSorting) {
      this.latestSorting = newSorting
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    fetchPageDebounced: debounce(async function (pageNumber, pageSize, sorting) {
      await this.fetchPage(pageNumber, pageSize, sorting)
    }, 250),
    async fetchPage (pageNumber, pageSize, sorting) {
      this.isLoading = true
      try {
        const pagedResponse = await this.fetchFileContainers({ pageNumber, pageSize, sorting })
        this.fileContainers = pagedResponse.items
        this.totalSize = pagedResponse.totalSize
      } catch (error) {
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('errors.loading-data-failed')],
          duration: 5000
        })
      } finally {
        this.isLoading = false
      }
    }
  },
  created () {
    this.pageNumber = parseInt(this.$route.query.page || this.pageNumber)
  },
  async mounted () {
    await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
  },
  watch: {
    pageNumber (newVal, oldVal) {
      // Do nothing if the route is already correct.
      if (this.$route.query.page && parseInt(this.$route.query.page) === newVal) return
      // Replace the page number in the route/url to be the new page number.
      const query = { ...this.$route.query, page: newVal }
      this.$router.replace({ query })
    }
  }
}
</script>
